
//DEFAULT : POPUP
.popup {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(245, 245, 245, 0.872);
    backdrop-filter: blur(10px);
    z-index: 3000;

    //Height : 50px
    &__close {
        height: 50px ; 
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        & div {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background-color: $red_medium;
            display: flex;
            justify-content: center;
            align-items: center;
            margin : 0px 10px ; 
            cursor: pointer;
            & svg {
                width: 15px;
                height: 15px;
                & path {

                }
            }
        }
    }

    &__container {
        display: flex;
        width : 100% ; 
        height : calc(100% - 50px)
    }
}

//POPUP-RECAPTCHA
.popup  {
    &__recaptcha {
        position: absolute;
        background-color: rgba(0, 8, 0, 0.54);
        z-index : 4000; 
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        backdrop-filter: blur(10px);

        &__close {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1000;
        }
    
        &__container {
            width: 90%;
            background-color: white;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            align-items: center;
            padding : 20px 0px ; 
            z-index: 2000;

            & h1 {
                color : $green-darken ; 
                height: 40px;

                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
                font-size: 25px;
                text-align: center;
            }

            & p {
                width: 90%;
                color : $green-darken ; 
                font-size: 16px;
                margin-bottom : 10px;
                text-align: center;
                
            }

            
            & button {
                margin-top: 10px;
                width: auto ; 
                padding : 0px 10px ; 
                height: 35px;
                font-size: 16px;
                border: none;
                border-radius: 10px;
                font-weight: bold;
                cursor: pointer;
                background-color: lighten($red_medium, 10);
                color: white;
                    

            }
    
        }
    
    


    }
}

.timer {
    display: flex;
    width: 90%;
    justify-content: space-around;
    margin-bottom: 20px;
    
    &__background {
        background-image: url('../assets/background.jpg');
        background-position: center;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;

        & h1 {
            height: 40px;
        }

        & > p {
            width: 80%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin: 0;
            margin-bottom: 15px;
        }

        & .session__user {
            position: absolute;
            top: 0px;
            left: 0px;



            & svg {

                &:first-child path { 
                    fill: red;
                }
            }
        }
    }

    & p {

    }

    & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        background: rgb(117,83,48);
        background: linear-gradient(157deg, rgb(168, 125, 81) 0%, rgba(163,115,66,1) 34%, rgb(182, 129, 75) 81%, rgba(163,115,66,1) 100%);

        border: 1px solid #ffd103;
        width: 70px;
        height: 70px;
        border-radius: 10px;

        & p {
            margin: 0;
            color: white;
            &:first-child {
                height: 30px;
                display: flex;
                align-items: flex-end;
                font-weight: bold;
            }

            &:last-child {
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 1 1;
                font-size: 24px;
                font-weight: 700;

            }
        }


    }
}

//GAMEPLAY 

.gameplay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.68);
    backdrop-filter: blur(15px);
    z-index: 4000;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > div {
        width: 100%;
    }

    //Height : 50px
    &__close {
        height: 50px ; 
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        & div {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background-color: $red_medium;
            display: flex;
            justify-content: center;
            align-items: center;
            margin : 0px 10px ; 
            cursor: pointer;
            & svg {
                width: 15px;
                height: 15px;
                & path {

                }
            }
        }
    }

    &__day {
        color : $green-darken ; 
        height: 60px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        font-size: 25px;
        text-align: center;
        

    }

    &__childpic {
        height : 250px ; 
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        & .crown {
            position: absolute;
            top: 50%;
            left : 50% ; 
            width : 250px ; 
            height: 250px ;
            transform: translateX(-50%) translateY(-50%);



        }

        & .pic {
            width: 160px;
            height: 160px;
            border-radius: 50%;
            overflow: hidden;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;

            & img {
                max-width: 100%;
                max-height: 100%;
            }


        }

        

    }

    &__content {
        flex: 1 1;

        & > h2 {

        }

        & .todayYou {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            & svg {
                width: 70%;
            }
        }

        & .choose {
            width: 100% ; 
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            & h2 {
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 700;
                color : $green-darken

            }

            &__pic {
                display: flex;
                justify-content: space-around;
                align-items: center;

                & .arrow {
                    cursor: pointer;
                    & svg {
                        width: 30px;
                    }

                    &:last-child svg {
                        transform: rotate(180deg);
                    }
                }

                & .user {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: center;
                    position: relative;
                    width: 150px;
                    height: 200px;


                    & .active {
                        -webkit-box-shadow: 0px 0px 19px 9px #E2B453; 
                        box-shadow: 0px 0px 19px 9px #E2B453;
                    }


                    & > div {
                        top: 0;
                        position: absolute;
                        width: 130px;
                        height: 130px;
                        background-color: white;
                        border: 1px solid rgba(197, 195, 195, 0.651);
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;


                        
    
                        & img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                        & svg {
                            width: 50px;
                            
                        }
    
                    }

                    & p {
                        text-align : center ; 
                        font-size: 17px;
                        font-weight: bold;
                        width: 100%;

                        & span {
                            text-transform: capitalize;
                        }


                    }
                }
            }

            & .button {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 60px;
                & button {
                    width: 70%;
                    height: 40px;
                    background-color: $red_medium;
                    border: none;
                    border-radius: 10px;
                    font-size: 1.3em;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $background;
                    cursor: pointer;
                    
                
        
                }
            }
        }

        & .results {
            display: flex;
            width: 100%;
            height: calc(100% - 60px);
                padding-bottom: 60px;
            flex-direction: column;
            justify-content: center;

            & > h2 {
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 700;
                color : $green-darken
            }

            & .user {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: relative;
 
                & .active {
                    -webkit-box-shadow: 0px 0px 19px 9px #E2B453; 
                    box-shadow: 0px 0px 19px 9px #E2B453;
                }


                & > div {
                    top: 0;
                    width: 150px;
                    height: 150px;
                    background-color: white;
                    border: 1px solid rgba(197, 195, 195, 0.651);
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;


                    

                    & img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                    & svg {
                        width: 50px;
                        
                    }

                }

                & p {
                    text-align : center ; 
                    font-size: 17px;
                    font-weight: bold;
                    width: 100%;

                    & span {
                        text-transform: capitalize;
                    }
                }
            }

            &__final {
                height: 60px;
                width: 100%;
                padding: 10px 0px;
                background-color: $red_medium;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                flex-direction: column;
                position: absolute;
                bottom: 0;

                & h2 {
                    color: whitesmoke;
                    font-size: 20px;
                    font-weight: 700;
                    margin: 0;
                }

                & p {
                    color: whitesmoke;
                    margin: 0;
                    font-weight: bold;
                }

                &__win {
                    background-color: $green-darken;
                }

                &__loose {
                    background-color: $red_medium;
                }
            }
        }


    }

}

//STATUS REQUEST

@keyframes bounce {
    0% {
        transform: scale(0.8);

    }
    25% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.8);
    }
    75% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.8);
    }
}

.statusRequest {
    position: absolute;
    top: 10px;
    left: 50%;
    //background-color: whitesmoke;
    z-index : 9000; 

    width: 80%;
    height: 60px;
    transform: translateX(-50%);
    //z-index: 3000;
    padding: 10px;
    display: flex;
    border-radius: 10px;
    justify-content: space-between;
    align-items: center;

    & > div {
        position: absolute;
        width: 60px;
        height: 60px;
        background-color: $background;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        
        

        & svg {
            height: 70%;
            position: absolute;
            animation: 2s bounce infinite ;
        }
    }

    & p {
        border-radius: 30px;
        padding-left: 70px;
        padding-right: 10px;
        flex: 1 1;
        height: 100%;
        margin: 0;
        display: flex;
        align-items: center;
        font-size: 16px;
        text-align: center;
        font-weight: 600;
    }

    &__cancel {
        //border: 3px solid $red_medium;

        & div {
            border: 3px solid #a81e2f;
        }

        & p {
            color : white ; 
            background-color: #a81e2f;
        }
    }

    &__check {
        //border: 3px solid $green-darken;
        & div {
            border: 3px solid #7c9725;
        }

        & p {
            color : white ; 
            background-color: #96b72e;
        }
    }

    &__warning {
        //border: 3px solid $dore;

        & div {
            border: 3px solid #dd7403;
        }

        & p {
            color : white ; 
            background-color : #dd7403;
        }
    }
    
    
    

}

//LOADER

@keyframes svganimation {
    0%{
        stroke-dashoffset: 0;
    }
    50%{
        stroke-dashoffset: 1000;
    }
    100%{
        stroke-dashoffset: 0;
    }
}


html{
  height: 100%;
}

body, html{

}


.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 7000;
    
    .tree{
        background-color: #0d2b1ccf;
        border : none ; 
        backdrop-filter: blur(20px);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    
    .tree svg {
        /* fill: none; */
        stroke-width: 1px;
        stroke: white;
        width: 40%;
        height: 40%; 
    }
    
    .tree svg .text{
        width: 10px;
        fill: white;
    }
    
    
    .tree svg .p {
        stroke-dashoffset: 700;
        stroke-dasharray: 70;
        animation: svganimation 20s linear forwards infinite;
    }
    
    .tree svg .c {
        stroke-dashoffset: 1000;
        stroke-dasharray: 13;
    }
    
    .tree svg .c1 {
        animation: svganimation 100s linear forwards infinite ;
    }
    
    .tree svg .c2 {
        animation: svganimation 100s linear forwards infinite ;
        animation-delay: .05s;
    }
    
    .tree svg .c3 {
        animation: svganimation 100s linear forwards infinite ;
        animation-delay: .25s;
    }
    
    .tree svg .c4 {
        animation: svganimation 100s linear forwards infinite ;
        animation-delay: .30s;
    }
    
    .tree svg .c5 {
        animation: svganimation 100s linear forwards infinite ;
        animation-delay: .20s;
    }
    
    .tree svg .c6 {
        animation: svganimation 100s linear forwards infinite ;
        animation-delay: .10s;
    }
    
    .tree svg .c7 {
        animation: svganimation 100s linear forwards infinite ;
        animation-delay: .15s;
    }
}

.viewer {
    background-color: #0d2b1ca4;
    backdrop-filter: blur(10px);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 7000;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
        width: 90%;
        border-radius: 20px;
        background-color: white;
    }
}

.activity {
    width : 90% ; 
    
}