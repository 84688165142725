
//HOMEPAGE
.homepage {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__logo {
        height: 20%;
        display: flex;
        justify-content: center;
        align-items: center;

        & svg {
            height: 80px;
        }
    }

    &__presentation {
        width: 100%;
        position: relative;
        height: 60%;
        display: flex;
        justify-content: center;
        align-items: center;

        & .branche {
            position: absolute;
            width: 100%;
            &:first-child {
                top: 0;
                left: 0;
                transform: scale(-1);
 
            }
            
            &:last-child {
                bottom: 0;
                left: 0;
            }
        }

        & .text {
            width: 70%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            text-align: center;
            font-size: 2em;
            color : $green-darken ; 
        }

    }

    &__buttons {
        height: 20%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        justify-content: space-evenly;

        & button {
            width: 90%;
            height: 45px;
            background-color: $red_medium;
            border: none;
            border-radius: 30px;
            font-size: 1.3em;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $background;
            cursor: pointer;

            
        

        }
    }
}

//REGISTER : Trouver une solution pour les lier les presentations avec logib
.homepage__presentation--register {
    width: 100%;
    position: relative;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 220ms;
    & .branche {
        position: absolute;
        width: 80px;

        & svg {

        }

        &:first-child {
            top: 0;
            left: -10px;


        }
        
        &:last-child {
            bottom: 0;
            right: -10px;
            & svg {


            }
        }
    }

    & .text {
        width: 60%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        text-align: center;
        font-size: 2em;
        color : $green-darken ; 
    }
}

.homepage__presentation--login {
    width: 100%;
    position: relative;
    flex : 1 1; 
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 220ms;
    & .branche {
        position: absolute;
        width: 80px;

        & svg {

        }

        &:first-child {
            top: 0;
            left: -10px;


        }
        
        &:last-child {
            bottom: 0;
            right: -10px;
            & svg {


            }
        }
    }

    & .text {
        width: 60%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        text-align: center;
        font-size: 2em;
        color : $green-darken ; 
    }
}


.register {
    width: calc(100% - 40px);
    height: 80%;
    background-color: $green-darken;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & .step {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    & h1 {
        height: 60px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        font-size: 25px;
        text-align: center;
    }

    & p {
        margin: 0;
        height: 50px;
        font-size: 15px;
        font-weight: 400;
        color: white;
        opacity: 0.8;
        text-align: center;
    }

    & form {
        flex: 1 1;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        & div {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin: 5px 0px;
            width: 80%;
            & label {
                font-weight: bold;
                text-align: center;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;

            }

            & input {
                width: 100%;
                height: 30px;
                border: none;
                border-radius: 20px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: whitesmoke;
                opacity: 0.9;
                transition: all 110ms;

                &:focus {
                    outline : none; 
                    //border : 1px solid lighten($green-darken, 10) ; 
                    opacity: 1;
                }

            }
        }
    }

    &__buttons {
        height: 60px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        & button {
            width: auto ; 
            padding : 0px 10px ; 
            height: 35px;
            font-size: 16px;
            border: none;
            border-radius: 10px;
            font-weight: bold;
            cursor: pointer;

            &:first-child {
                background-color: $background;
                color: $green-darken;
            }

            &:last-child {
                background-color: lighten($red_medium, 10);
                color: white;
                
            }
        }

    }

    &__childPic {
        width: 100%;
        flex: 1 1 ; 
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        & .filePreview {
        background-color: $background;
            overflow: hidden;
            width: 200px;
            height: 200px;
            border-radius: 50%;
            position: relative;

            & img {
                width: 100%;
                max-height: 150%;
                position: absolute;
            }
        }

        & > div {
            height: calc(90% - 90px);
            margin: 10px 0px;
            display: flex;
            justify-content: center;
            align-items: center;

            & svg {
                height: 130px;
                opacity: 0.6;

                & path {
                    fill: whitesmoke;

                }
            }
        }

        & button {
            width: 90%;
            height: 70px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            border: none;
            background-color: $red_medium;
            border: 1px solid whitesmoke;
            cursor: pointer;

            & p {
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0px;
                color: white;
            }

            & svg {
                margin: 5px 0px;
                height: 30px;

                & path {
                    fill: white;
                }
                
            }
        }

    }


}

.login {
    width: calc(100% - 40px);
    height: 70%;
    background-color: $green-darken;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & h1 {
        height: 80px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        font-size: 25px;
        text-align: center;
    }

    & p {
        margin: 0;
        height: 60px;
        font-size: 15px;
        font-weight: 400;
        color: white;
        opacity: 0.8;
        text-align: center;
    }

    & form {
        flex : 1 1 ; 
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        
        & div {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin: 10px 0px;
            & label {
                font-weight: bold;
                text-align: center;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;

            }

            & input {
                width: 90%;
                height: 30px;
                border: none;
                border-radius: 20px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: whitesmoke;
                opacity: 0.9;
                transition: all 110ms;

                &:focus {
                    outline : none; 
                    //border : 1px solid lighten($green-darken, 10) ; 
                    opacity: 1;
                }

            }
        }
    }


}