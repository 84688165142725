

.twentyFour {
    display: flex;
    flex-direction: column;
    //background-color: red;
    flex: 1 1; position: relative;
    align-items: center;

    & h1 {
        color : $green-darken ; 
        height: 50px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        font-size: 25px;
        text-align: center;
    }

    & > p {
        width: 90%;
        text-align: justify;
        height: 80px;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        font-size: 16px;
        color: $green-darken;
        margin: 0;


        


    }

    &__pic {
        height: 200px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        & .crown {
            position: absolute;
            top: 50%;
            left : 50% ; 
            width : 200px ; 
            height: 200px ;
            transform: translateX(-50%) translateY(-50%);



        }

        & .pic {
            width: 130px;
            height: 130px;
            border-radius: 50%;
            overflow: hidden;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;

            & img {
                max-width: 100%;
                max-height: 100%;
            }


        }

        

    }


    & .arrow {
        position: absolute;
        cursor: pointer;
        top: 50%;
        transform: translateY(-50%);
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        z-index: 3000;
        & svg {
            width: 30px;
        }


        &__left {
            left: 20px;
            justify-content: flex-start;
        }

        &__right {
            right: 20px;
            justify-content: flex-end;

            & svg {
                transform: rotate(180deg);
            }
        }

    }

    & .response {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2000;


        & label {
            display: flex;
            justify-content: center;
            margin: 10px 0px;
        }


        &__chooser {
            position: relative;
            height: 30px;
            width: 80%;

            & input {
                width: 100%;
                height: 100%;
                border: none;
                background : linear-gradient(157deg,#a87d51,#a37342 34%,#b6814b 81%,#a37342); 
                border : 2px solid #ffd103 ;  
                border-radius: 30px;
                text-align: center;
                color: #F5F5F5;
                font-size: 17px;
                z-index: 1000; 

    
            }


            & > div {
                position: absolute;
                top: 60px;
                height: 200px;
                overflow-y: scroll;
                width: 100%;
                padding-left: 20px;

                & > div {
                    width: 100%;
                    height: 50px;
                    cursor: pointer;
                    font-size: 20px;
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    color: #402f1e;
                    
                    & span {
                        text-transform: capitalize;
                    }
                }
            }
        }
    }

    & .submit {
        position: fixed;
        bottom: 10px;
        right: 10px;
        border-radius: 10px;
        border: none;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 230px;
        z-index: 3000;

        color: white;
        font-weight: bold;
        font-size: 17px;

        &__success {
            opacity: 1;
            background-color: $red-medium;
            cursor: pointer;
        }

        &__pending {
            background-color: $dore;
            opacity: 0.6;
            cursor: not-allowed;
        }
    }

    & .saved {
        width: 250px;
        text-align: center;
        font-weight: bold;
        color: rgb(1, 134, 1);
        margin: 10px 0px;
        

        & svg {
            margin: 10px 0px;

            & path {
                fill : rgb(1, 134, 1)
            }
        }
    }

    & .noData {
        position: absolute;
        top : 0 ; 
        left : -10px; 
        width: 200px;
        height: 130px!important;
        text-align: center;
        font-weight: bold;
        color: $green-darken;
        margin: 10px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0px!important;
        

        & svg {
            width: 50px;
            height: 50px;
            margin: 10px 0px;

            & path {
                fill : $green-darken;
            }
        }

    }



}