@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");
* {
  font-family: "Comfortaa", cursive;
}

button {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html, body {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
  background: #f6f6f6;
  overflow: hidden;
  position: relative;
}
@media (min-height: 928px) {
  #root {
    height: 928px;
  }
}
@media (min-width: 428px) {
  #root {
    width: 428px;
  }
}

.homepage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.homepage__logo {
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homepage__logo svg {
  height: 80px;
}
.homepage__presentation {
  width: 100%;
  position: relative;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homepage__presentation .branche {
  position: absolute;
  width: 100%;
}
.homepage__presentation .branche:first-child {
  top: 0;
  left: 0;
  transform: scale(-1);
}
.homepage__presentation .branche:last-child {
  bottom: 0;
  left: 0;
}
.homepage__presentation .text {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
  font-size: 2em;
  color: #15452D;
}
.homepage__buttons {
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}
.homepage__buttons button {
  width: 90%;
  height: 45px;
  background-color: #B31846;
  border: none;
  border-radius: 30px;
  font-size: 1.3em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f6f6f6;
  cursor: pointer;
}

.homepage__presentation--register {
  width: 100%;
  position: relative;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 220ms;
}
.homepage__presentation--register .branche {
  position: absolute;
  width: 80px;
}
.homepage__presentation--register .branche:first-child {
  top: 0;
  left: -10px;
}
.homepage__presentation--register .branche:last-child {
  bottom: 0;
  right: -10px;
}
.homepage__presentation--register .text {
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
  font-size: 2em;
  color: #15452D;
}

.homepage__presentation--login {
  width: 100%;
  position: relative;
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 220ms;
}
.homepage__presentation--login .branche {
  position: absolute;
  width: 80px;
}
.homepage__presentation--login .branche:first-child {
  top: 0;
  left: -10px;
}
.homepage__presentation--login .branche:last-child {
  bottom: 0;
  right: -10px;
}
.homepage__presentation--login .text {
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
  font-size: 2em;
  color: #15452D;
}

.register {
  width: calc(100% - 40px);
  height: 80%;
  background-color: #15452D;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.register .step {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.register h1 {
  height: 60px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 25px;
  text-align: center;
}
.register p {
  margin: 0;
  height: 50px;
  font-size: 15px;
  font-weight: 400;
  color: white;
  opacity: 0.8;
  text-align: center;
}
.register form {
  flex: 1 1;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.register form div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 5px 0px;
  width: 80%;
}
.register form div label {
  font-weight: bold;
  text-align: center;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.register form div input {
  width: 100%;
  height: 30px;
  border: none;
  border-radius: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
  opacity: 0.9;
  transition: all 110ms;
}
.register form div input:focus {
  outline: none;
  opacity: 1;
}
.register__buttons {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.register__buttons button {
  width: auto;
  padding: 0px 10px;
  height: 35px;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
}
.register__buttons button:first-child {
  background-color: #f6f6f6;
  color: #15452D;
}
.register__buttons button:last-child {
  background-color: #e01e58;
  color: white;
}
.register__childPic {
  width: 100%;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.register__childPic .filePreview {
  background-color: #f6f6f6;
  overflow: hidden;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  position: relative;
}
.register__childPic .filePreview img {
  width: 100%;
  max-height: 150%;
  position: absolute;
}
.register__childPic > div {
  height: calc(90% - 90px);
  margin: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.register__childPic > div svg {
  height: 130px;
  opacity: 0.6;
}
.register__childPic > div svg path {
  fill: whitesmoke;
}
.register__childPic button {
  width: 90%;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: none;
  background-color: #B31846;
  border: 1px solid whitesmoke;
  cursor: pointer;
}
.register__childPic button p {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  color: white;
}
.register__childPic button svg {
  margin: 5px 0px;
  height: 30px;
}
.register__childPic button svg path {
  fill: white;
}

.login {
  width: calc(100% - 40px);
  height: 70%;
  background-color: #15452D;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.login h1 {
  height: 80px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 25px;
  text-align: center;
}
.login p {
  margin: 0;
  height: 60px;
  font-size: 15px;
  font-weight: 400;
  color: white;
  opacity: 0.8;
  text-align: center;
}
.login form {
  flex: 1 1;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.login form div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px 0px;
}
.login form div label {
  font-weight: bold;
  text-align: center;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.login form div input {
  width: 90%;
  height: 30px;
  border: none;
  border-radius: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
  opacity: 0.9;
  transition: all 110ms;
}
.login form div input:focus {
  outline: none;
  opacity: 1;
}

.session {
  position: relative;
  width: calc(100% - 20px);
  height: 100%;
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  background-color: #F4DFE8;
  background-image: url("../assets/background2.svg");
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}
.session__theEnd {
  background-image: url("../assets/backgroundTheEnd.svg");
  background-position: right;
  background-size: 200%;
  background-repeat: no-repeat;
}
.session__theEnd h1 {
  height: 20px !important;
}
.session__illustration {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  bottom: 100px;
  display: flex;
  justify-content: flex-end;
}
.session__user {
  width: 100%;
  margin-top: 5px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  position: static;
}
.session__user button {
  width: 45px;
  height: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.session__user button:first-child svg path {
  fill: red !important;
}
.session__user button:last-child svg path {
  fill: #10880c;
}
.session h1 {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #15452D;
  font-size: 25px;
}
.session .today {
  position: relative;
  height: calc(100% - 80px - 55px - 100px);
  flex: 1, 1;
  z-index: 1000;
}
.session .today__box {
  width: 50%;
  height: 50%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.session .today__box p {
  margin: 10px 0px;
  font-size: 20px;
  font-weight: bold;
  color: #15452D;
}
.session .today__box div {
  width: 120px;
  height: 120px;
  display: flex;
  background: #755330;
  background: linear-gradient(157deg, #a87d51 0%, #a37342 34%, #b6814b 81%, #a37342 100%);
  border: 4px solid #ffd103;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  color: #ffd103;
  font-weight: bold;
}
.session .today__tree {
  position: absolute;
  height: 100%;
  left: 40%;
  top: 0;
}
.session .today__tree svg {
  height: 90%;
}
.session__buttons {
  height: 150px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  z-index: 1000;
}
.session__buttons button, .session__buttons a {
  width: 90%;
  height: 45px;
  text-decoration: none;
  background-color: #B31846;
  border: none;
  border-radius: 30px;
  font-size: 1.3em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f6f6f6;
  cursor: pointer;
}

.scores {
  width: 100%;
  height: 100%;
}
.scores h1 {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #15452D;
  font-size: 25px;
}
.scores > div {
  height: calc(100% - 80px);
  background-color: #15452D;
  position: relative;
  width: calc(100% - 10px);
  background-color: #15452D;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 0px 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.scores > div .branche {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  width: 65%;
  height: 100px;
}
.scores > div .table {
  margin-top: 50px;
  height: calc(100% - 50px);
}
.scores > div .table .thead {
  width: 100%;
  display: flex;
}
.scores > div .table .thead div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  font-weight: bold;
  font-style: italic;
  height: 40px;
}
.scores > div .table .thead div:first-child {
  width: 80%;
}
.scores > div .table .thead div:last-child {
  width: 20%;
}
.scores > div .table .tbody {
  width: 100%;
  height: calc(100% - 40px);
  position: relative;
}
.scores > div .table .tbody .scrollContent {
  display: flex;
  flex-direction: column;
}
.scores > div .table .tbody .scrollContent > div {
  display: flex;
  flex-direction: column;
}
.scores > div .table .tbody .noData {
  display: flex;
  height: inherit;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}
.scores > div .table .tbody .noData svg {
  width: 150px;
}
.scores > div .table .tbody__gradient {
  position: absolute;
  background: #15452d;
  background: linear-gradient(0deg, #15452d 36%, rgba(21, 69, 45, 0) 100%);
  bottom: 0;
  height: 50px;
  z-index: 2000;
  width: 100%;
  bottom: 10px;
  left: 0;
}
.scores > div .table .tbody > div {
  width: 100% !important;
  height: 100% !important;
}
.scores > div .table .tbody__element {
  display: flex;
  align-items: center;
  height: 60px;
  margin: 10px 0px;
}
.scores > div .table .tbody__element .you {
  color: #fdc754;
  font-weight: 700;
}
.scores > div .table .tbody__element div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  font-size: 17px;
  height: 40px;
}
.scores > div .table .tbody__element div > span {
  width: 50px;
}
.scores > div .table .tbody__element div p {
  padding-left: 10px;
}
.scores > div .table .tbody__element div p span {
  text-transform: capitalize;
}
.scores > div .table .tbody__element div:first-child {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.scores > div .table .tbody__element div:first-child > div {
  background-color: white;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.scores > div .table .tbody__element div:first-child > div svg {
  width: 20px;
}
.scores > div .table .tbody__element div:first-child > div img {
  max-width: 100%;
  max-height: 100%;
}
.scores > div .table .tbody__element div:last-child {
  width: 20%;
}
.scores > div .table .tbody__element:last-child {
  margin-bottom: 60px;
}

.calendar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.calendar--popup {
  z-index: 2000 !important;
}
.calendar h1 {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #15452D;
  font-size: 25px;
  margin: 0;
}
.calendar .branche {
  height: 60px;
  margin-bottom: 10px;
}
.calendar .branche svg {
  height: 100%;
}
.calendar .content {
  width: 100%;
  height: calc(100% - 40px - 70px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.calendar .content > div {
  width: 60px;
  height: 60px;
  margin: 5px 5px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: white;
}
.calendar__today {
  background-color: #C49C48 !important;
  -webkit-box-shadow: 0px 0px 19px 9px #E2B453;
  box-shadow: 0px 0px 19px 9px #E2B453;
  cursor: pointer;
}
.calendar__passed {
  background-color: #B31846 !important;
  cursor: pointer;
}
.calendar__coming {
  background-color: #590c23 !important;
  cursor: not-allowed;
  opacity: 0.5;
}

.activity {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}
.activity h1 {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #15452D;
  font-size: 25px;
}
.activity > div {
  width: 100%;
  height: calc(100% - 80px);
  background-color: #15452D;
  position: relative;
  width: calc(100% - 10px);
  background-color: #15452D;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 0px 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.activity > div .table {
  margin-top: 20px;
  height: calc(100% - 20px);
}
.activity > div .table .thead {
  width: 100%;
  display: flex;
}
.activity > div .table .thead__days div:nth-child(1) {
  width: 20%;
}
.activity > div .table .thead__days div:nth-child(2) {
  width: 60%;
}
.activity > div .table .thead__days div:nth-child(3) {
  width: 20%;
}
.activity > div .table .thead div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  font-weight: bold;
  font-style: italic;
  height: 40px;
}
.activity > div .table .tbody {
  width: 100%;
  height: calc(100% - 40px);
  position: relative;
}
.activity > div .table .tbody .scrollContent {
  display: flex;
  flex-direction: column;
}
.activity > div .table .tbody .scrollContent > div {
  width: 100%;
}
.activity > div .table .tbody .scrollContent > div > div {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.activity > div .table .tbody .scrollContent > div > div > div {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.activity > div .table .tbody .scrollContent > div > div > div:nth-child(1) {
  width: 20%;
  position: relative;
}
.activity > div .table .tbody .scrollContent > div > div > div:nth-child(1) div {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.activity > div .table .tbody .scrollContent > div > div > div:nth-child(1) div svg {
  width: 40px;
}
.activity > div .table .tbody .scrollContent > div > div > div:nth-child(2) {
  width: 60%;
  text-transform: capitalize;
}
.activity > div .table .tbody .scrollContent > div > div > div:nth-child(3) {
  width: 20%;
}

.afterChristmas {
  display: flex;
  flex: 1 1;
  height: 300px;
  justify-content: center;
  align-items: center;
}
.afterChristmas svg {
  height: 100%;
}

.popup {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(245, 245, 245, 0.872);
  backdrop-filter: blur(10px);
  z-index: 3000;
}
.popup__close {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.popup__close div {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #B31846;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
  cursor: pointer;
}
.popup__close div svg {
  width: 15px;
  height: 15px;
}
.popup__container {
  display: flex;
  width: 100%;
  height: calc(100% - 50px);
}

.popup__recaptcha {
  position: absolute;
  background-color: rgba(0, 8, 0, 0.54);
  z-index: 4000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
}
.popup__recaptcha__close {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
.popup__recaptcha__container {
  width: 90%;
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px;
  z-index: 2000;
}
.popup__recaptcha__container h1 {
  color: #15452D;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 25px;
  text-align: center;
}
.popup__recaptcha__container p {
  width: 90%;
  color: #15452D;
  font-size: 16px;
  margin-bottom: 10px;
  text-align: center;
}
.popup__recaptcha__container button {
  margin-top: 10px;
  width: auto;
  padding: 0px 10px;
  height: 35px;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  background-color: #e01e58;
  color: white;
}

.timer {
  display: flex;
  width: 90%;
  justify-content: space-around;
  margin-bottom: 20px;
}
.timer__background {
  background-image: url("../assets/background.jpg");
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.timer__background h1 {
  height: 40px;
}
.timer__background > p {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0;
  margin-bottom: 15px;
}
.timer__background .session__user {
  position: absolute;
  top: 0px;
  left: 0px;
}
.timer__background .session__user svg:first-child path {
  fill: red;
}
.timer > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: #755330;
  background: linear-gradient(157deg, #a87d51 0%, #a37342 34%, #b6814b 81%, #a37342 100%);
  border: 1px solid #ffd103;
  width: 70px;
  height: 70px;
  border-radius: 10px;
}
.timer > div p {
  margin: 0;
  color: white;
}
.timer > div p:first-child {
  height: 30px;
  display: flex;
  align-items: flex-end;
  font-weight: bold;
}
.timer > div p:last-child {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  font-size: 24px;
  font-weight: 700;
}

.gameplay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.68);
  backdrop-filter: blur(15px);
  z-index: 4000;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.gameplay > div {
  width: 100%;
}
.gameplay__close {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.gameplay__close div {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #B31846;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
  cursor: pointer;
}
.gameplay__close div svg {
  width: 15px;
  height: 15px;
}
.gameplay__day {
  color: #15452D;
  height: 60px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 25px;
  text-align: center;
}
.gameplay__childpic {
  height: 250px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gameplay__childpic .crown {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 250px;
  height: 250px;
  transform: translateX(-50%) translateY(-50%);
}
.gameplay__childpic .pic {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gameplay__childpic .pic img {
  max-width: 100%;
  max-height: 100%;
}
.gameplay__content {
  flex: 1 1;
}
.gameplay__content .todayYou {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.gameplay__content .todayYou svg {
  width: 70%;
}
.gameplay__content .choose {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.gameplay__content .choose h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: #15452D;
}
.gameplay__content .choose__pic {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.gameplay__content .choose__pic .arrow {
  cursor: pointer;
}
.gameplay__content .choose__pic .arrow svg {
  width: 30px;
}
.gameplay__content .choose__pic .arrow:last-child svg {
  transform: rotate(180deg);
}
.gameplay__content .choose__pic .user {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  width: 150px;
  height: 200px;
}
.gameplay__content .choose__pic .user .active {
  -webkit-box-shadow: 0px 0px 19px 9px #E2B453;
  box-shadow: 0px 0px 19px 9px #E2B453;
}
.gameplay__content .choose__pic .user > div {
  top: 0;
  position: absolute;
  width: 130px;
  height: 130px;
  background-color: white;
  border: 1px solid rgba(197, 195, 195, 0.651);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.gameplay__content .choose__pic .user > div img {
  max-width: 100%;
  max-height: 100%;
}
.gameplay__content .choose__pic .user > div svg {
  width: 50px;
}
.gameplay__content .choose__pic .user p {
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  width: 100%;
}
.gameplay__content .choose__pic .user p span {
  text-transform: capitalize;
}
.gameplay__content .choose .button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}
.gameplay__content .choose .button button {
  width: 70%;
  height: 40px;
  background-color: #B31846;
  border: none;
  border-radius: 10px;
  font-size: 1.3em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f6f6f6;
  cursor: pointer;
}
.gameplay__content .results {
  display: flex;
  width: 100%;
  height: calc(100% - 60px);
  padding-bottom: 60px;
  flex-direction: column;
  justify-content: center;
}
.gameplay__content .results > h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: #15452D;
}
.gameplay__content .results .user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.gameplay__content .results .user .active {
  -webkit-box-shadow: 0px 0px 19px 9px #E2B453;
  box-shadow: 0px 0px 19px 9px #E2B453;
}
.gameplay__content .results .user > div {
  top: 0;
  width: 150px;
  height: 150px;
  background-color: white;
  border: 1px solid rgba(197, 195, 195, 0.651);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.gameplay__content .results .user > div img {
  max-width: 100%;
  max-height: 100%;
}
.gameplay__content .results .user > div svg {
  width: 50px;
}
.gameplay__content .results .user p {
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  width: 100%;
}
.gameplay__content .results .user p span {
  text-transform: capitalize;
}
.gameplay__content .results__final {
  height: 60px;
  width: 100%;
  padding: 10px 0px;
  background-color: #B31846;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  position: absolute;
  bottom: 0;
}
.gameplay__content .results__final h2 {
  color: whitesmoke;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}
.gameplay__content .results__final p {
  color: whitesmoke;
  margin: 0;
  font-weight: bold;
}
.gameplay__content .results__final__win {
  background-color: #15452D;
}
.gameplay__content .results__final__loose {
  background-color: #B31846;
}

@keyframes bounce {
  0% {
    transform: scale(0.8);
  }
  25% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  75% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
.statusRequest {
  position: absolute;
  top: 10px;
  left: 50%;
  z-index: 9000;
  width: 80%;
  height: 60px;
  transform: translateX(-50%);
  padding: 10px;
  display: flex;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
}
.statusRequest > div {
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: #f6f6f6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.statusRequest > div svg {
  height: 70%;
  position: absolute;
  animation: 2s bounce infinite;
}
.statusRequest p {
  border-radius: 30px;
  padding-left: 70px;
  padding-right: 10px;
  flex: 1 1;
  height: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
}
.statusRequest__cancel div {
  border: 3px solid #a81e2f;
}
.statusRequest__cancel p {
  color: white;
  background-color: #a81e2f;
}
.statusRequest__check div {
  border: 3px solid #7c9725;
}
.statusRequest__check p {
  color: white;
  background-color: #96b72e;
}
.statusRequest__warning div {
  border: 3px solid #dd7403;
}
.statusRequest__warning p {
  color: white;
  background-color: #dd7403;
}

@keyframes svganimation {
  0% {
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
html {
  height: 100%;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 7000;
}
.loader .tree {
  background-color: #0d2b1ccf;
  border: none;
  backdrop-filter: blur(20px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.loader .tree svg {
  /* fill: none; */
  stroke-width: 1px;
  stroke: white;
  width: 40%;
  height: 40%;
}
.loader .tree svg .text {
  width: 10px;
  fill: white;
}
.loader .tree svg .p {
  stroke-dashoffset: 700;
  stroke-dasharray: 70;
  animation: svganimation 20s linear forwards infinite;
}
.loader .tree svg .c {
  stroke-dashoffset: 1000;
  stroke-dasharray: 13;
}
.loader .tree svg .c1 {
  animation: svganimation 100s linear forwards infinite;
}
.loader .tree svg .c2 {
  animation: svganimation 100s linear forwards infinite;
  animation-delay: 0.05s;
}
.loader .tree svg .c3 {
  animation: svganimation 100s linear forwards infinite;
  animation-delay: 0.25s;
}
.loader .tree svg .c4 {
  animation: svganimation 100s linear forwards infinite;
  animation-delay: 0.3s;
}
.loader .tree svg .c5 {
  animation: svganimation 100s linear forwards infinite;
  animation-delay: 0.2s;
}
.loader .tree svg .c6 {
  animation: svganimation 100s linear forwards infinite;
  animation-delay: 0.1s;
}
.loader .tree svg .c7 {
  animation: svganimation 100s linear forwards infinite;
  animation-delay: 0.15s;
}

.viewer {
  background-color: #0d2b1ca4;
  backdrop-filter: blur(10px);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 7000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.viewer img {
  width: 90%;
  border-radius: 20px;
  background-color: white;
}

.activity {
  width: 90%;
}

.twentyFour {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  position: relative;
  align-items: center;
}
.twentyFour h1 {
  color: #15452D;
  height: 50px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 25px;
  text-align: center;
}
.twentyFour > p {
  width: 90%;
  text-align: justify;
  height: 80px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  font-size: 16px;
  color: #15452D;
  margin: 0;
}
.twentyFour__pic {
  height: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.twentyFour__pic .crown {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  transform: translateX(-50%) translateY(-50%);
}
.twentyFour__pic .pic {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.twentyFour__pic .pic img {
  max-width: 100%;
  max-height: 100%;
}
.twentyFour .arrow {
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  z-index: 3000;
}
.twentyFour .arrow svg {
  width: 30px;
}
.twentyFour .arrow__left {
  left: 20px;
  justify-content: flex-start;
}
.twentyFour .arrow__right {
  right: 20px;
  justify-content: flex-end;
}
.twentyFour .arrow__right svg {
  transform: rotate(180deg);
}
.twentyFour .response {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2000;
}
.twentyFour .response label {
  display: flex;
  justify-content: center;
  margin: 10px 0px;
}
.twentyFour .response__chooser {
  position: relative;
  height: 30px;
  width: 80%;
}
.twentyFour .response__chooser input {
  width: 100%;
  height: 100%;
  border: none;
  background: linear-gradient(157deg, #a87d51, #a37342 34%, #b6814b 81%, #a37342);
  border: 2px solid #ffd103;
  border-radius: 30px;
  text-align: center;
  color: #F5F5F5;
  font-size: 17px;
  z-index: 1000;
}
.twentyFour .response__chooser > div {
  position: absolute;
  top: 60px;
  height: 200px;
  overflow-y: scroll;
  width: 100%;
  padding-left: 20px;
}
.twentyFour .response__chooser > div > div {
  width: 100%;
  height: 50px;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #402f1e;
}
.twentyFour .response__chooser > div > div span {
  text-transform: capitalize;
}
.twentyFour .submit {
  position: fixed;
  bottom: 10px;
  right: 10px;
  border-radius: 10px;
  border: none;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 230px;
  z-index: 3000;
  color: white;
  font-weight: bold;
  font-size: 17px;
}
.twentyFour .submit__success {
  opacity: 1;
  background-color: #B31846;
  cursor: pointer;
}
.twentyFour .submit__pending {
  background-color: #C49C48;
  opacity: 0.6;
  cursor: not-allowed;
}
.twentyFour .saved {
  width: 250px;
  text-align: center;
  font-weight: bold;
  color: #018601;
  margin: 10px 0px;
}
.twentyFour .saved svg {
  margin: 10px 0px;
}
.twentyFour .saved svg path {
  fill: #018601;
}
.twentyFour .noData {
  position: absolute;
  top: 0;
  left: -10px;
  width: 200px;
  height: 130px !important;
  text-align: center;
  font-weight: bold;
  color: #15452D;
  margin: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px !important;
}
.twentyFour .noData svg {
  width: 50px;
  height: 50px;
  margin: 10px 0px;
}
.twentyFour .noData svg path {
  fill: #15452D;
}

