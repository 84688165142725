
* {
    font-family: 'Comfortaa', cursive;
}

button {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html, body {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

}

#root {
    width: 100%;
    height: 100%;
    background: $background ; 
    overflow: hidden;
    position: relative;
    @media (min-height: 928px) {

        height: 928px;
    }
    @media (min-width: 428px) {
        width: 428px;

    }

}