
.session {
    position: relative;
    width: calc(100% - 20px);
    height: 100%;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    background-color: #F4DFE8;
    background-image: url('../assets/background2.svg');
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;

    &__theEnd {
        background-image: url('../assets/backgroundTheEnd.svg');
        background-position: right;
        background-size: 200%;
        background-repeat: no-repeat;
        & h1 {
            height: 20px!important;
        }
    }

    &__illustration {
        position : absolute ; 
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        bottom: 100px;
        display: flex;
        justify-content: flex-end;

    }

    //height : 55px
    &__user {
        width: 100%;
        margin-top: 5px;
        height: 50px;
        display: flex;
        justify-content: space-between;

        position: static;

        & button {
            width: 45px;
            height: 100%;
            background-color: transparent;
            border: none;
            cursor: pointer;

            &:first-child svg path {
                fill : rgb(255, 0, 0)!important
            }

            &:last-child svg path {
                fill : #10880c
            }

        }
    }



    & h1 {
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color : $green-darken ; 
        font-size: 25px;
    }

    & .today {
        position: relative;
        height: calc(100% - 80px - 55px - 100px);
        flex : 1, 1; 
        z-index: 1000;

        &__box {
            width: 50%;
            height: 50%;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            align-items: center;
            cursor: pointer;

            & p {
                margin: 10px 0px;
                font-size: 20px;
                font-weight: bold;
                color : $green-darken ; 

            }

            & div {
                width : 120px ; 
                height: 120px ;
                display: flex;
                background: rgb(117,83,48);
                background: linear-gradient(157deg, rgb(168, 125, 81) 0%, rgba(163,115,66,1) 34%, rgb(182, 129, 75) 81%, rgba(163,115,66,1) 100%);
                border: 4px solid #ffd103;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size : 50px ; 
                color : #ffd103; 
                font-weight: bold;
            }
        }

        &__tree {
            position: absolute;
            height: 100%;
            left: 40%;
            top: 0;
            & svg {
                height: 90%;
            }
        }
    }

    &__buttons {
        height: 150px;
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        z-index: 1000;

        & button, a {
            width: 90%;
            height: 45px;
            text-decoration: none;
            background-color: $red_medium;
            border: none;
            border-radius: 30px;
            font-size: 1.3em;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $background;
            cursor: pointer;
        

        }
    }


}

.scores {
    width: 100%;
    height: 100%;
    & h1 {
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color : $green-darken ; 
        font-size: 25px;
    }

    & > div {
        height: calc(100% - 80px);
        background-color: $green-darken;
        position: relative;
        width: calc(100% - 10px);
        background-color: $green-darken;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: 0px 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        & .branche {
            position: absolute;
            top: -40px;
            left: 50%;
            transform: translateX(-50%);
            width: 65%;
            height: 100px;
        }

        & .table {
            margin-top: 50px;
            height: calc(100% - 50px);

            & .thead {
                width: 100%;
                display: flex;


                & div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    color: white;
                    font-weight: bold;
                    font-style: italic;
                    height: 40px;
                    &:first-child {
                        width: 80%;
                    }

                    &:last-child {
                        width: 20%;

                    }
                }
            }

            & .tbody {
                width: 100%;
                height: calc(100% - 40px);
                position: relative;

                & .scrollContent {
                    display: flex;
                    flex-direction: column;

                    & > div {
                        display: flex;
                        flex-direction: column;
                    }
                }


                & .noData {
                    display: flex;
                    height: inherit;
                    justify-content: center;
                    align-items: center;
                    flex: 1 1;

                    & svg {
                        width: 150px;
                    }

                }

                &__gradient {
                    position: absolute;
                    background: rgb(21,69,45);
                    background: linear-gradient(0deg, rgba(21,69,45,1) 36%, rgba(21,69,45,0) 100%); 
                    bottom: 0;
                    height: 50px;
                    z-index: 2000;
                    width: 100%;
                    bottom: 10px;
                    left: 0;
                }

                & > div {
                    width: 100%!important;
                    height: 100%!important;
                }
                    &__element {
                        display: flex;
                        align-items: center;
                        height: 60px;

                        margin: 10px 0px;

                        & .you {
                            color : #fdc754 ; 
                            font-weight : 700 ; 
                        }

                        & div {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                            color: white;
                            font-size: 17px;


                            & > span {
                                width: 50px;
                                //padding : 0px 20px ; 
                            }

                            & p {
                                padding-left: 10px;
                                & span {
                                    text-transform: capitalize;
                                }
                            }


                            height: 40px;
                            &:first-child {
                                width: 80%;
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-start;

                                & > div {
                                    background-color: white;
                                    width: 45px;
                                    height: 45px;
                                    border-radius: 50%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    overflow: hidden;

                                    & svg {
                                        width: 20px;
                                    }

                                    & img {
                                        max-width: 100%;
                                        max-height: 100%;
                                    }



       
                                }



                            }
        
                            &:last-child {
                                width: 20%;
        
                            }
                        }

                        &:last-child {
                            margin-bottom: 60px;
                        }


                        
                    }

            }
        }
    }
}

.calendar {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &--popup {
        z-index: 2000!important;
    }

    & h1 {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color : $green-darken ; 
        font-size: 25px;
        margin: 0;
    }

    & .branche {
        height: 60px;
        margin-bottom: 10px;

        & svg {
            height: 100%;
        }
    }

    & .content {
        width: 100%;
        height: calc(100% - 40px - 70px);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        & > div {
            width: 60px;
            height: 60px;
            margin: 5px 5px;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: bold;
            color: white;


            //background-color: $red_medium;
            
        }
    }

    &__today {
        background-color: $dore!important;
        -webkit-box-shadow: 0px 0px 19px 9px #E2B453; 
        box-shadow: 0px 0px 19px 9px #E2B453;
        cursor: pointer;
    }

    &__passed { 
        background-color: $red_medium!important;
        cursor: pointer;
    }



    &__coming {
        background-color: darken($red_medium, 20)!important;
        cursor:not-allowed;
        opacity: 0.5;

    }
}


.activity {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    & h1 {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color : $green-darken ; 
        font-size: 25px;
    }

    & > div {
        width: 100%;
        height: calc(100% - 80px);
        background-color: $green-darken;
        position: relative;
        width: calc(100% - 10px);
        background-color: $green-darken;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: 0px 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        & .table {
            margin-top: 20px;
            height: calc(100% - 20px);

            & .thead {
                width: 100%;
                display: flex;

                &__days {

                    & div {
                        &:nth-child(1){
                            width: 20%;
                        }
    
                        &:nth-child(2){
                            width: 60%;
                        }

                        &:nth-child(3){
                            width: 20%;
                        }
                    }

                }


                & div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    color: white;
                    font-weight: bold;
                    font-style: italic;
                    height: 40px;
                }
            }

            & .tbody {
                width: 100%;
                height: calc(100% - 40px);
                position: relative;

                & .scrollContent {
                    display: flex;
                    flex-direction: column;

                    & > div {

                        width: 100%;

                        & > div {
                            width: 100%;
                            height: 70px;
                            display: flex  ; 
                            justify-content: center;
                            align-items: center;
                            text-align: center;

                            & > div {
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                color: white;
                                &:nth-child(1){
                                    width: 20%;
                                    position: relative;

                                    & div {
                                        height: 100%;
                                        width: 100%;
                                        position: absolute;
                                        left: 0;
                                        top: 0;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;

                                        & svg {

                                            width: 40px;
                                        }
                                    }
        

                                }
            
                                &:nth-child(2){
                                    width: 60%;
                                    text-transform: capitalize;
                                }
        
                                &:nth-child(3){
                                    width: 20%;
                                }
                            }
 
                        }
                    }
                }



            }
        }
    }
}


.afterChristmas { 
    display: flex;
    flex: 1 1;
    height: 300px;
    justify-content: center;
    align-items: center;
    & svg {
        height: 100%;
    }
}